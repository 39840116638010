<template>
  <div class="profile-content">
    <div class="text-center"  v-if="!dataLoaded">
      <div class="loading-icon"></div>
    </div>
    <template v-if="dataLoaded">
      <div class="empty text-center" v-if="!coupons.length">
        {{$t('coupons.tipEmpty')}}
        <p>
          <router-link :to="{name: 'profile-invitation'}">
            <button class="btn default small">{{$t('coupons.buttonLoadMore')}}</button>
          </router-link>
        </p>
      </div>
      <div class="profile-coupon-content" v-if="coupons.length">
        <coupon-item v-for="(coupon, index) in coupons"
                     :coupon="coupon" :key="index"></coupon-item>
        <p class="text-center">
          <router-link :to="{name: 'profile-invitation'}">
            <button class="btn default small">{{$t('coupons.buttonLoadMore')}}</button>
          </router-link>
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import api from 'src/api/'
import CouponItem from '../../components/layout/Coupon.vue'
import { isEmpty } from 'lodash'
export default {
  name: 'profile-coupons',
  components: { CouponItem },
  data () {
    return {
      dataLoaded: false,
      coupons: [],
      couponNo: ''
    }
  },
  created () {
    this.fetchCoupons()
  },
  methods: {
    fetchCoupons () {
      return api.fetchAllCoupon().then(data => {
        this.dataLoaded = true
        if (!data || data.errors) {
          this.$toast({
            message: '優惠券加載失敗',
            position: 'bottom'
          })
          return
        }
        const items = data.map(item => {
          if (!isEmpty(item.accountCoupon)) {
            item.coupon.rest = item.accountCoupon.rest
            item.coupon.used = item.accountCoupon.used
          }
          return item.coupon
        })
        let coupons = items.filter(item => (item.scope !== 'group_buying' || item.rules.groupAvaliable) && Date.now() >= new Date(item.beginAt).getTime())
        const sortKeys = ['all', 'diarybook', 'photobook', 'calendar']
        const sortObj = coupons.reduce((sortMap, coupon) => {
          if (!sortMap[coupon.bookType]) {
            sortMap[coupon.bookType] = []
          }
          sortMap[coupon.bookType].push(coupon)
          return sortMap
        }, {})
        coupons = sortKeys.reduce((list, key) => {
          if (sortObj[key]) {
            list = list.concat(sortObj[key])
          }
          return list
        }, [])
        this.coupons = coupons
      })
    }
  }
}
</script>

<style lang="scss">
.profile-content {
  padding: 1rem;
  padding-bottom: 2rem;
  max-width: 680px;
  margin: 0 auto;
  .check-coupon {
    margin-bottom: 1.5rem;
  }
}
</style>
